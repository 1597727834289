.App {

  height: 100vh;
  min-height: 100vh;
  overflow-x: hidden;
 
  
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@import url(https://fonts.googleapis.com/css?family=Khula:700);

.hidden {
  opacity:0;
}
.console-container {
 
  font-family:Khula;
  font-size:4em;
  text-align:center;
  height:100px;
  width:600px;
  display:block;
  
  color: white;
  background-color: black;
  top:0;
  bottom:0;
  left:0;
  right:0;
  margin:auto;
  inline-size: max-content;
}
.console-underscore {
   display:inline-block;
  position:relative;
  top:-0.14em;
  left:10px;
}

/* TopNav */
#primary-navigation li {
  display: inline-block;
  margin: 0 8rem;
  padding: 0 8px;
  vertical-align: middle;
  font-size: 1rem;
  list-style: none;
  
  

 }

  nav li, nav ul {
    margin: 0 1rem;
    padding: 0 1px;
    list-style: none; }
   
   li a {
      text-decoration: none;
      color: white;
      
    }
   li a:hover {

      color:  #00B3E3;

    }

    /* Card texture */

    .texture {
      width: 80vw;
      left: 10%;
      height: 40vh;
      overflow: auto;
      background: rgba(black, 0.66);
      color: white;
      position: relative;
      padding: 1rem; ;
    }

    /* Portfolio Cards */
    
    .portfoliocard {
      width:75%; 
      border: 3px solid black;
    }